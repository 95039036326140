import React from 'react';
import { Flex } from 'antd';

const excludedKeys = ['label', 'key', 'logoSrc', 'path', 'favourite'];
export const findGroupKeys = (section) => (
  Object
    .keys(section)
    .filter((key) => !excludedKeys.includes(key))
);

export const link = (path, label) => (
  <a className="navigation-side-menu__link" href={path}>
    {label}
  </a>
);

export const divider = { type: 'divider' };

export const buildSection = (section, builder) => {
  const groupKeys = findGroupKeys(section);

  return groupKeys.flatMap((groupKey, index) => {
    const showDivider = index !== groupKeys.length - 1;

    if (showDivider) {
      return [builder(section, groupKey), divider];
    }

    return [builder(section, groupKey)];
  });
};

export const languageLabel = (item, locale) => {
  const findCurrentLanguage = item.children
    .find((child) => child.key === locale);

  return (
    <>
      <span>{item.label}</span>
      <span style={{ fontWeight: '400', color: '#696f88' }}>
        {findCurrentLanguage.label}
      </span>
    </>
  );
};

export const userLabel = (item) => {
  const style = { fontWeight: '400', fontSize: '12px', color: '#696f88' };
  const label = (
    <Flex vertical align="start">
      <span>{item.label}</span>
      <span style={style}>
        {item.email}
      </span>
    </Flex>
  );

  return link(item.path, label);
};
