import React, { useCallback, useRef, useState } from 'react';
import I18n from 'i18n-js';
import {
  set_current_year_path,
  top_menu_field_groups_path,
  top_menu_machine_regions_path,
  set_current_production_cycles_path,
  set_current_machine_regions_path,
  work_recording_start_path,
} from 'js-routes/generated/routes';
import {
  arrayOf, bool, func, number, oneOfType, shape, string,
} from 'prop-types';
import {
  Spin,
  Badge,
  Space,
  Button,
  Drawer,
  Select,
  Collapse,
  notification,
  Tooltip,
} from 'antd';
import { isOrchard, isMachinery } from 'helpers/userSessionSettings';
import { camelizeKeys } from 'humps';
import axios from 'axios';
import { capitalize, isEmpty, isEqual } from 'lodash-es';
import { DownOutlined, WarningFilled } from '@ant-design/icons';
import {
  FieldGroupIcon,
  CropCycleIcon,
  CalendarIcon,
  CombineIcon,
  InfoIcon,
} from 'components/cwo-icons';
import flattenTopMenuItemsToMap from 'helpers/flattenTopMenuItemsToMap';
import toggleBemModifier from 'helpers/toggle-bem-modifier';
import ProductionCyclesSettings from './nested/ProductionCyclesSettings';
import prepareItems, { ALL_GROUPS_KEY } from './helpers/prepareItems';
import preparedMachineryRegionsItems from './machinery-regions-helpers/prepareItems';
import initializeCheckedKeys from './helpers/initializeCheckedKeys';
import defineTreeKeys from './helpers/defineTreeKeys';
import defineApplyUrl from './helpers/defineApplyUrl';
import defineApplyRegionIds from './machinery-regions-helpers/defineApplyRegionIds';
import checkedKeysCompare from './helpers/checkedKeysCompare';
import FieldGroupsSettings from './nested/FieldGroupsSettings';
import MachineRegionsSettings from './nested/MachineRegionsSettings';
import usePostRequest from './hooks/usePostRequest';
import ToggleBtn from './nested/ToggleBtn';
import useDispatcherTimer from './hooks/useDispatcherTimer';

const topMenuTitle = I18n.t(`top_menu_right_part.${isOrchard ? 'orchard_plots' : 'field_groups'}`);
const topMachineMenuTitle = I18n.t('top_menu_right_part.machine_regions');

const TopMenuFieldGroups = ({
  options,
  filterButtonsRef,
  dispatcherBlockRef,
  filterDrawerOpen,
  setFilterDrawerOpen,
  filterCollapseActiveKey,
  setFilterCollapseActiveKey,
}) => {
  const {
    title,
    seasonsByYears,
    productionCycleLabel,
    yearLabel,
    machineRegionsTitle,
    settings,
  } = options;

  const {
    currentYear,
    currentProductionCycleIds,
    dispatcherWorkRecord,
    showAllGroups,
    showAllMachineRegions,
    productionCyclesBySeasons,
  } = settings;

  const { current: initSettings } = useRef({
    expandKeys: [],
    expandAll: false,
    expandAllRegions: true,
    expandedRegionsKeys: [],
    initialCheckedKeys: { checked: [], halfChecked: [] },
    initialRegionsCheckedKeys: { checked: [], halfChecked: [] },
  });

  const visible = false;
  const [fieldGroups, setFieldGroups] = useState([]);
  const [machineRegions, setMachineRegions] = useState([]);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [sendingMachineRegionsRequest, setSendingMachineRegionsRequest] = useState(false);
  const [plainItemsIndex, setPlainItemsIndex] = useState(new Map());
  const [plainRegionsItemsIndex, setRegionsPlainItemsIndex] = useState(new Map());
  const [treeKeys, setTreeKeys] = useState(initSettings.initialCheckedKeys);
  const [regionsTreeKeys, setRegionsTreeKeys] = useState(initSettings.initialRegionsCheckedKeys);
  const [applyUrl, setApplyUrl] = useState(null);
  const [applyRegionIds, setApplyRegionIds] = useState(null);
  const [noEvalableRegions, setNoEvalableRegions] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(0);
  const postRequest = usePostRequest();

  const loadMachineRegionsData = useCallback(async () => {
    if (!isEmpty(machineRegions) || sendingMachineRegionsRequest) { return; }

    setSendingMachineRegionsRequest(true);

    const url = top_menu_machine_regions_path();
    const data = await postRequest(url);
    const { items, expandAll, expandKeys } = camelizeKeys(data);
    if (isEmpty(items)) {
      setNoEvalableRegions(true);
      setSendingMachineRegionsRequest(false);
      return;
    }
    const machineryRegionsFmt = preparedMachineryRegionsItems(items || []);
    const plainMapIndex = flattenTopMenuItemsToMap(machineryRegionsFmt);
    setMachineRegions(items);
    initSettings.expandAllRegions = expandAll;
    initSettings.expandedRegionsKeys = expandKeys;
    initSettings.initialRegionsCheckedKeys =
      initializeCheckedKeys(plainMapIndex, showAllMachineRegions);

    setRegionsTreeKeys(initSettings.initialRegionsCheckedKeys);
    setRegionsPlainItemsIndex(plainMapIndex);
    setMachineRegions(machineryRegionsFmt);

    setSendingMachineRegionsRequest(false);
    setNoEvalableRegions(false);
  }, [
    postRequest,
    machineRegions,
    showAllMachineRegions,
    sendingMachineRegionsRequest,
    initSettings]);

  const loadFieldGroupsData = useCallback(async () => {
    if (!isEmpty(fieldGroups) || sendingRequest) { return; }

    setSendingRequest(true);

    const url = top_menu_field_groups_path();
    const data = await postRequest(url);
    const { items, expandAll, expandKeys } = camelizeKeys(data);
    const fieldGroupsFmt = prepareItems(items || [], showAllGroups);
    const plainMapIndex = flattenTopMenuItemsToMap(fieldGroupsFmt);

    initSettings.expandAll = expandAll;
    initSettings.expandKeys = expandKeys;
    initSettings.initialCheckedKeys = initializeCheckedKeys(plainMapIndex, showAllGroups);

    setTreeKeys(initSettings.initialCheckedKeys);
    setPlainItemsIndex(plainMapIndex);
    setFieldGroups(fieldGroupsFmt);

    setSendingRequest(false);
  }, [
    postRequest,
    fieldGroups,
    initSettings,
    sendingRequest,
    showAllGroups,
  ]);

  const loadMenuData = useCallback(async () => {
    loadFieldGroupsData();
    loadMachineRegionsData();
  }, [
    loadFieldGroupsData,
    loadMachineRegionsData,
  ]);

  const onCheck = (_, { checked: nodeIsChecked, node }) => {
    const newTreeKeys = defineTreeKeys({
      plainItemsIndex,
      node,
      nodeIsChecked,
      currentTreeKeys: treeKeys,
      allGroupsKey: ALL_GROUPS_KEY,
    });

    const newCheckedArr = [...newTreeKeys.checked];
    const newHalfCheckedArr = [...newTreeKeys.halfChecked];

    setTreeKeys({
      checked: newCheckedArr,
      halfChecked: newHalfCheckedArr,
    });

    const { initialCheckedKeys: { checked, halfChecked } } = initSettings;
    const isCheckedEqual = checkedKeysCompare(checked, newCheckedArr);
    const isHalfCheckedEqual = checkedKeysCompare(halfChecked, newHalfCheckedArr);

    const initialSelectionWasModified = !isCheckedEqual || !isHalfCheckedEqual;

    setApplyUrl(
      initialSelectionWasModified
        ? defineApplyUrl(newTreeKeys.checked, plainItemsIndex)
        : null,
    );
  };

  const onCheckRegion = (_, { checked: nodeIsChecked, node }) => {
    const newTreeKeys = defineTreeKeys({
      plainItemsIndex: plainRegionsItemsIndex,
      node,
      nodeIsChecked,
      currentTreeKeys: regionsTreeKeys,
    });

    const newCheckedArr = [...newTreeKeys.checked];
    const newHalfCheckedArr = [...newTreeKeys.halfChecked];

    setRegionsTreeKeys({
      checked: newCheckedArr,
      halfChecked: newHalfCheckedArr,
    });

    const { initialRegionsCheckedKeys: { checked, halfChecked } } = initSettings;
    const isCheckedEqual = checkedKeysCompare(checked, newCheckedArr);
    const isHalfCheckedEqual = checkedKeysCompare(halfChecked, newHalfCheckedArr);

    const initialSelectionWasModified = !isCheckedEqual || !isHalfCheckedEqual;

    setApplyRegionIds(
      initialSelectionWasModified
        ? defineApplyRegionIds(newTreeKeys.checked, plainRegionsItemsIndex)
        : null,
    );
  };

  const fieldGroupsFilterClasses = toggleBemModifier(
    'top-menu-field-group-filter__filter',
    'loading',
    sendingRequest,
  );

  const machineRegionssFilterClasses = toggleBemModifier(
    'top-menu-field-group-filter__regions_filter',
    'loading',
    sendingMachineRegionsRequest,
  );

  const [sendingChanges, setSendingChanges] = useState(false);
  const [currentSeason, setCurrentSeason] = useState(currentYear);
  const [checkedProdCycleKeys, setCheckedProdCycleKeys] = useState(currentProductionCycleIds);

  const { showWorkRecord, initialSeconds } = dispatcherWorkRecord;
  const { dispatcherTimer } = useDispatcherTimer(showWorkRecord, initialSeconds);

  const { Panel } = Collapse;

  const onClose = () => {
    setFilterDrawerOpen(false);
    setCurrentSeason(currentYear);
    setCheckedProdCycleKeys(currentProductionCycleIds);

    setTreeKeys(initSettings.initialCheckedKeys);
    setRegionsTreeKeys(initSettings.initialRegionsCheckedKeys);
    setApplyUrl(null);
    setApplyRegionIds(null);
  };

  const openCollapseActiveKey = (keyNumber) => {
    setFilterCollapseActiveKey(keyNumber);
  };

  const showDrawer = (collapseKey) => {
    setFilterDrawerOpen(true);
    openCollapseActiveKey(collapseKey);
  };

  const seasonSelectList = Object.entries(seasonsByYears)
    .map(([year, name]) => ({ value: year, label: `${name} (${year})` }))
    .reverse();

  const notificationMessage = (message) => {
    notification.warning({
      message,
      style: { backgroundColor: '#FFF3DD', width: 'auto' },
      placement: 'bottom',
      duration: 5,
      icon: <WarningFilled style={{ color: '#C17E19' }} />,
    });
  };

  const validateParams = () => {
    let isValid = true;

    if (checkedProdCycleKeys.length === 0) {
      notificationMessage(I18n.t('top_menu_right_part.please_select_product_cycle'));
      isValid = false;
    }

    if (treeKeys.checked.length === 0) {
      notificationMessage(I18n.t(`top_menu_right_part.please_select_${isOrchard ? 'orchard_plot' : 'field_group'}`));
      isValid = false;
    }

    if ((regionsTreeKeys.checked.length === 0) && isMachinery && !noEvalableRegions) {
      notificationMessage(I18n.t('top_menu_right_part.please_select_machine_region'));
      isValid = false;
    }

    return isValid;
  };

  const changedFormParams = () => (
    currentSeason.toString() !== currentYear.toString()
    || !isEqual(checkedProdCycleKeys.sort(), currentProductionCycleIds.sort())
    || applyUrl || applyRegionIds
  );

  const saveChanges = async () => {
    if (!validateParams()) return;
    if (!changedFormParams()) return;

    setSendingChanges(true);

    /* eslint-disable camelcase */
    try {
      if (showWorkRecord && initialSeconds && applyRegionIds) {
        await axios.post(
          work_recording_start_path(),
          { machine_region_ids: applyRegionIds },
        );
      }

      if (currentSeason.toString() !== currentYear.toString()) {
        const currentSeasonUrl = set_current_year_path(currentSeason);
        await axios.get(currentSeasonUrl);
      }

      if (applyUrl) {
        const currentFieldGroupsUrl = applyUrl;
        await axios.get(currentFieldGroupsUrl);
      }

      if (applyRegionIds) {
        await axios.get(set_current_machine_regions_path({
          machine_region_ids: applyRegionIds,
        }));
      }

      if (!isEqual(checkedProdCycleKeys, currentProductionCycleIds)) {
        const currentProductionCycleUrl = set_current_production_cycles_path(
          { production_cycle_ids: checkedProdCycleKeys },
        );
        await axios.get(currentProductionCycleUrl);
      }

      // eslint-disable-next-line no-undef
      window.location.reload();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setSendingChanges(false);
    }
    /* eslint-enable camelcase */
  };

  const getDrawerHeight = () => {
    // eslint-disable-next-line no-undef
    const drawerBody = document.querySelector('.ant-drawer-body');
    const drawerBodyHeight = drawerBody?.clientHeight;

    setDrawerHeight(drawerBodyHeight);
  };

  const drawerStyles = {
    header: {
      borderLeft: '5px solid #8354d6',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      padding: '16px 12px',
    },
    footer: {
      textAlign: 'right',
    },
  };

  const fieldGroupsPanelName = () => (
    <b>
      {I18n.t(`activerecord.attributes.field.${isOrchard ? 'orchard_plot' : 'field_group'}`, { count: 5 })}
      <Badge
        size="small"
        count={treeKeys.checked.filter((key) => key.startsWith('g_')).length}
        showZero
        color="#c6e6ff"
        offset={[6, 0]}
        style={{
          boxShadow: 'none',
          color: '#0172cc',
          borderRadius: '10px',
          minWidth: '30px',
          minHeight: '20px',
          fontWeight: '600',
          lineHeight: '19px',
        }}
      />
    </b>
  );

  const productionCyclesPanelName = () => (
    <>
      <b>{capitalize(I18n.t('production_cycles.index.production_cycles'))}</b>
      <Badge
        size="small"
        count={checkedProdCycleKeys.length}
        showZero
        color="#fed7de"
        offset={[6, 0]}
        style={{
          boxShadow: 'none',
          color: '#bb485d',
          borderRadius: '10px',
          minWidth: '30px',
          minHeight: '20px',
          fontWeight: '600',
          lineHeight: '19px',
        }}
      />
    </>
  );

  const machineRegionsPanelName = () => (
    <b>
      {I18n.t('top_menu_right_part.machine_regions')}
      <Badge
        size="small"
        count={regionsTreeKeys.checked.filter((key) => key.startsWith('r_')).length}
        showZero
        color="#fee4ae"
        offset={[6, 0]}
        style={{
          boxShadow: 'none',
          color: '#98660e',
          borderRadius: '10px',
          minWidth: '30px',
          minHeight: '20px',
          fontWeight: '600',
          lineHeight: '19px',
        }}
      />
    </b>
  );

  const machineRegionsPanelContent = () => {
    if (noEvalableRegions) {
      return (<p>{I18n.t('top_menu_right_part.no_available_machine_regions')}</p>);
    }

    return (
      <MachineRegionsSettings
        items={machineRegions}
        checkedKeys={regionsTreeKeys}
        onCheck={onCheckRegion}
        plainItemsIndex={plainRegionsItemsIndex}
        visible={visible}
        expandAll={initSettings.expandAllRegions}
        expandKeys={initSettings.expandedRegionsKeys}
        drawerHeight={drawerHeight}
        dispatcherTimer={dispatcherTimer}
        dispatcherWorkRecord={dispatcherWorkRecord}
        saveChanges={saveChanges}
        validateParams={validateParams}
        dispatcherBlockRef={dispatcherBlockRef}
      />
    );
  };

  const toggleBtnSeasonAddContent = (
    <div className="top-menu-field-groups__btn-additional-content">
      <span
        style={{ background: '#F6F2FE' }}
        className="top-menu-field-groups__btn-icon-container"
      >
        <CalendarIcon />
      </span>
      <div className="top-menu-field-groups__btn-text-container">
        <span className="top-menu-field-groups__btn-text-container__label">
          {I18n.t('top_menu_right_part.current_season')}
        </span>
        <span className="top-menu-field-groups__btn-text-container__value">
          {yearLabel}
        </span>
      </div>
    </div>
  );

  const dispatcherTimerContent = (showWorkRecord && initialSeconds) && (
    <div className="top-menu-field-groups__dispatcher-timer">
      <small>{dispatcherTimer}</small>
    </div>
  );

  return (
    (
      <div className="top-menu-field-groups">
        <div ref={filterButtonsRef} className="top-menu-field-groups__toggle-btn-group">
          <ToggleBtn // Season and Field group
            showDrawer={showDrawer}
            loadMenuData={loadMenuData}
            iconBackgroundColor="#EAF6FF"
            toggleKey={1}
            icon={<FieldGroupIcon />}
            id="season-field-groups-btn"
            label={topMenuTitle}
            value={title.length > 12 ? title.slice(0, 9).concat('...') : title}
            additionalContent={toggleBtnSeasonAddContent}
          />
          <ToggleBtn // Production cycles
            showDrawer={showDrawer}
            loadMenuData={loadMenuData}
            iconBackgroundColor="#FFF1F3"
            toggleKey={2}
            icon={<CropCycleIcon />}
            id="production-cycles-btn"
            label={I18n.t('top_menu_right_part.production_cycles')}
            value={productionCycleLabel}
          />
          <ToggleBtn // Machine regions
            showDrawer={showDrawer}
            loadMenuData={loadMenuData}
            iconBackgroundColor="#FFF3DD"
            toggleKey={3}
            icon={<CombineIcon color="#9A6412" width="20" height="20" />}
            id="machinery-regions-btn"
            isVisible={isMachinery}
            label={topMachineMenuTitle}
            dispatcherTimerContent={dispatcherTimerContent}
            value={
              machineRegionsTitle.length > 12 ?
                machineRegionsTitle.slice(0, 9).concat('...') : machineRegionsTitle
            }
          />
        </div>

        <Drawer
          className="top-menu-drawer"
          closable
          // @ts-ignore
          styles={drawerStyles}
          placement="right"
          afterOpenChange={getDrawerHeight}
          onClose={onClose}
          open={filterDrawerOpen}
          zIndex={1032}
          footer={(
            <Space className="top-menu-footer">
              <Button
                type="primary"
                loading={sendingChanges}
                onClick={saveChanges}
                disabled={!changedFormParams()}
              >
                {I18n.t('apply')}
              </Button>
            </Space>
          )}
          extra={(
            <Space id="season-panel" className="top-menu-field-group-filter__season-panel">
              <span><b>{I18n.t('top_menu_right_part.current_season')}</b></span>
              <Select
                className="top-menu-field-group-filter__season-select"
                id="season-select"
                showSearch
                defaultValue={currentSeason.toString()}
                value={currentSeason.toString()}
                onChange={(value) => setCurrentSeason(value)}
                filterOption={
                  (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={seasonSelectList}
              />
              <Tooltip title={I18n.t(`top_menu_right_part.filter_info${isOrchard ? '_orchard' : ''}`)}>
                <span
                  className="top-menu-field-group-filter__info-icon"
                  style={{ marginLeft: '28px' }}
                >
                  <InfoIcon />
                </span>
              </Tooltip>
            </Space>
          )}
        >
          <Collapse
            accordion
            expandIconPosition="end"
            activeKey={filterCollapseActiveKey}
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon={({ isActive }) => (!isActive && <DownOutlined style={{ marginRight: '0px' }} />)}
            ghost
          >
            <Panel
              header={fieldGroupsPanelName()}
              className="top-menu-field-group-filter__header top-menu-field-group-filter__field-groups"
              // @ts-ignore
              onClick={() => openCollapseActiveKey(1)}
              key="1"
              id="field-groups-panel"
            >
              <div className={fieldGroupsFilterClasses}>
                {isEmpty(fieldGroups) ? <Spin size="large" /> : (
                  <FieldGroupsSettings
                    items={fieldGroups}
                    checkedKeys={treeKeys}
                    onCheck={onCheck}
                    plainItemsIndex={plainItemsIndex}
                    visible={visible}
                    expandAll={initSettings.expandAll}
                    expandKeys={initSettings.expandKeys}
                    drawerHeight={drawerHeight}
                  />
                )}
              </div>
            </Panel>

            <Panel
              header={productionCyclesPanelName()}
              className="top-menu-field-group-filter__header top-menu-field-group-filter__production-cycles"
              // @ts-ignore
              onClick={() => openCollapseActiveKey(2)}
              key="2"
              id="production-cycles-panel"
            >
              <div className="top-menu-field-groups__production-cycle-filter">
                <ProductionCyclesSettings
                  currentYear={currentYear}
                  currentSeason={currentSeason}
                  productionCyclesBySeasons={productionCyclesBySeasons}
                  checkedProdCycleKeys={checkedProdCycleKeys}
                  setCheckedProdCycleKeys={setCheckedProdCycleKeys}
                  drawerHeight={drawerHeight}
                />
              </div>
            </Panel>

            {isMachinery && (
              <Panel
                header={machineRegionsPanelName()}
                className="top-menu-field-group-filter__header top-menu-field-group-filter__machine-regions"
                // @ts-ignore
                onClick={() => openCollapseActiveKey(3)}
                key="3"
                id="machine-regions-panel"
              >
                <div className={machineRegionssFilterClasses}>
                  {(isEmpty(machineRegions) && sendingMachineRegionsRequest) ? <Spin size="large" /> : machineRegionsPanelContent()}
                </div>
              </Panel>
            )}
          </Collapse>
        </Drawer>
      </div>
    )
  );
};

TopMenuFieldGroups.propTypes = {
  options: shape({
    title: string,
    machineRegionsTitle: string,
    settings: shape({
      availableYears: arrayOf(number),
      currentYear: number,
      showAdditionalObjects: bool,
      showAllGroups: bool,
      winterMode: bool,
      currentProductionCycleIds: arrayOf(number),
    }),
    seasonsByYears: shape({}).isRequired,
    productionCyclesBySeasons: arrayOf(shape({})),
    productionCycleLabel: oneOfType([string, number]).isRequired,
    yearLabel: oneOfType([string, number]).isRequired,
    showAllMachineRegions: bool,
  }),
  filterButtonsRef: shape({}),
  dispatcherBlockRef: shape({}),
  filterDrawerOpen: bool,
  setFilterDrawerOpen: func,
  filterCollapseActiveKey: number,
  setFilterCollapseActiveKey: func,
};

TopMenuFieldGroups.defaultProps = {
  options: {
    title: ' - ',
    machineRegionsTitle: ' - ',
    settings: {},
    productionCyclesBySeasons: [],
    showAllMachineRegions: false,
  },
  filterButtonsRef: null,
  dispatcherBlockRef: null,
  filterDrawerOpen: false,
  setFilterDrawerOpen: () => {},
  filterCollapseActiveKey: 1,
  setFilterCollapseActiveKey: () => {},
};

export default TopMenuFieldGroups;
