import React, { useEffect, useState } from 'react';
import {
  AgroOperationIcon,
  CombineIcon,
  MonitoringCenterIcon,
  ReportsIcon,
  ProductivityMap,
  DescriptionIcon,
  FieldIcon,
  MoreHorizontalIcon,
  PlatformIcon,
} from 'components/cwo-icons';
import I18n from 'i18n-js';
import { isEmpty } from 'lodash-es';
import { Button } from 'antd';
import FavouriteButton from 'components/favourite-button';
import { findGroupKeys } from '../helpers';

const ITEM_CLASS_NAME = 'navigation-side-menu__item';
const LINK_ITEM_CLASS_NAME = 'navigation-side-menu__link-item';

const SINGLE_ITEM_CLASS_NAME = 'navigation-side-menu__single-item';
const LINK_SINGLE_ITEM_CLASS_NAME = 'navigation-side-menu__link-single-item';

const ITEM_GROUP_CLASS_NAME = 'navigation-side-menu__item-group';

const SUBMENU_CLASS_NAME = 'navigation-side-menu__submenu';
const SUBMENU_TITLE_CLASS_NAME = 'navigation-side-menu__submenu-title';

export const highlightSection = (currentOpenSection, sectionKey) => (
  currentOpenSection === sectionKey ? 'ant-menu-submenu-selected' : ''
);

const moreItem = (key, setExpandedReportGroups) => ([{
  key,
  label: (
    <Button
      style={{ color: '#a3a9b9' }}
      type="link"
      className={LINK_ITEM_CLASS_NAME}
    >
      {I18n.t('shared.top_menu.additional')}
      <i
        className="ant-menu-submenu-arrow"
        style={{ right: '17.5px', top: '20px' }}
      />
    </Button>
  ),
  style: { paddingInlineStart: '0px', paddingInline: '0px' },
  className: ITEM_CLASS_NAME,
  onClick: (e) => {
    setExpandedReportGroups((prev) => ({ ...prev, [e.key]: !prev[e.key] }));
  },
}]);

const favouriteLabel = (item, favouriteItems, setFavouriteItems) => (
  <Button
    type="link"
    href={item.path}
    className={LINK_ITEM_CLASS_NAME}
  >
    {item.label}
    <FavouriteButton
      item={item}
      favouriteItems={favouriteItems}
      setFavouriteItems={setFavouriteItems}
      className="navigation-side-menu__favourite-button"
      saveKey="selected_favorite_report_keys"
    />
  </Button>
);

const buildReportsPersistentGroup = ({
  group,
  divider = false,
  favouriteItems,
  setFavouriteItems,
  expandedReportGroups,
  setExpandedReportGroups,
  selectedKey,
}) => {
  if (isEmpty(group)) return [];

  const { items } = group;
  const moreItemKey = `more-${group.key}`;

  const firstFiveItems = items.slice(0, 5).map((item) => ({
    key: item.key,
    label: favouriteLabel(item, favouriteItems, setFavouriteItems),
    style: { paddingInlineStart: '0px', paddingInline: '0px' },
    className: ITEM_CLASS_NAME,
  })).concat(moreItem(moreItemKey, setExpandedReportGroups));

  const restItemKeys = items.slice(5).map(({ key }) => key);

  const allItems = items.map((item) => ({
    key: item.key,
    label: favouriteLabel(item, favouriteItems, setFavouriteItems),
    style: { paddingInlineStart: '0px', paddingInline: '0px' },
    className: ITEM_CLASS_NAME,
  }));

  const showAll = expandedReportGroups[moreItemKey] || restItemKeys.includes(selectedKey);

  return [
    {
      key: group.key,
      label: group.label,
      type: 'group',
      className: ITEM_GROUP_CLASS_NAME,
      children: (showAll || allItems.length <= 5) ? allItems : firstFiveItems,
    },
    divider && { type: 'divider' },
  ];
};

const buildReportsFavouriteGroup = (favourite, favouriteItems, setFavouriteItems) => {
  if (!favouriteItems.length) return [];

  return [
    {
      key: favourite.key,
      label: favourite.label,
      type: 'group',
      className: ITEM_GROUP_CLASS_NAME,
      children: favouriteItems.map((item) => ({
        key: `favourite_${item.key}`,
        label: favouriteLabel(item, favouriteItems, setFavouriteItems),
        style: { paddingInlineStart: '0px', paddingInline: '0px' },
        className: ITEM_CLASS_NAME,
      })),
    },
    { type: 'divider' },
  ];
};

const buildItems = (items) => (
  items.map((item, index) => {
    if (item.key === 'divider') {
      return {
        key: `divider-${item.key}-${index}`,
        type: 'divider',
      };
    }

    return {
      key: item.key,
      label: (
        <Button
          color="default"
          type="link"
          href={item.path}
          className={LINK_ITEM_CLASS_NAME}
        >
          {item.label}
        </Button>
      ),
      style: { paddingInlineStart: '0px', paddingInline: '0px' },
      className: ITEM_CLASS_NAME,
    };
  })
);

const buildGroup = ({ divider = false, group }) => {
  if (isEmpty(group) || isEmpty(group.items)) return [];

  return [
    {
      type: 'group',
      key: group.key,
      label: group.label,
      className: ITEM_GROUP_CLASS_NAME,
      children: buildItems(group.items),
    },
    divider && { type: 'divider' },
  ];
};

const buildMonitoringCenterSection = (monitoringCenter, currentOpenSection) => {
  const { items } = monitoringCenter;
  const icon = <MonitoringCenterIcon className="side-menu-icons" />;

  if (isEmpty(items)) {
    return [{
      key: monitoringCenter.key,
      label: (
        <Button
          type="link"
          href={monitoringCenter.path}
          className={LINK_SINGLE_ITEM_CLASS_NAME}
          icon={icon}
        >
          <span className={SUBMENU_TITLE_CLASS_NAME}>
            {monitoringCenter.label}
          </span>
        </Button>
      ),
      style: { paddingLeft: '0px' },
      className: SINGLE_ITEM_CLASS_NAME,
    }];
  }

  return [
    {
      key: monitoringCenter.key,
      label: (
        <span className={SUBMENU_TITLE_CLASS_NAME}>
          {monitoringCenter.label}
        </span>
      ),
      icon,
      className: `
        ${SUBMENU_CLASS_NAME}
        ${highlightSection(currentOpenSection, monitoringCenter.key)}
      `,
      children: buildItems(items),
    },
  ];
};

const buildAgroSection = (agro, currentOpenSection) => {
  if (isEmpty(agro) || isEmpty(findGroupKeys(agro))) {
    return [];
  }

  return [
    {
      key: agro.key,
      label: (
        <span className={SUBMENU_TITLE_CLASS_NAME}>
          {agro.label}
        </span>
      ),
      className: `
        ${SUBMENU_CLASS_NAME}
        ${highlightSection(currentOpenSection, agro.key)}
      `,
      icon: <AgroOperationIcon className="side-menu-icons" />,
      children: [
        ...buildItems(agro.items),
        { type: 'divider' },
        ...buildGroup({ group: agro.scouting, divider: true }),
        ...buildGroup({ group: agro.productPlus, divider: true }),
        ...buildGroup({ group: agro.reports }),
      ],
    },
  ];
};

const buildTelematicsSection = (telematics, currentOpenSection) => {
  if (isEmpty(telematics) || isEmpty(findGroupKeys(telematics))) {
    return [];
  }

  return [
    {
      key: telematics.key,
      label: (
        <span className={SUBMENU_TITLE_CLASS_NAME}>
          {telematics.label}
        </span>
      ),
      className: `
        ${SUBMENU_CLASS_NAME}
        ${highlightSection(currentOpenSection, telematics.key)}
      `,
      icon: <CombineIcon className="side-menu-icons" />,
      children: [
        ...buildGroup({ group: telematics.monitoring, divider: true }),
        ...buildGroup({ group: telematics.lists, divider: true }),
        ...buildGroup({ group: telematics.reports }),
      ],
    },
  ];
};

const buildReportsSection = (
  reports,
  favouriteItems,
  setFavouriteItems,
  expandedReportGroups,
  setExpandedReportGroups,
  selectedKey,
  currentOpenSection,
) => {
  if (isEmpty(reports) || isEmpty(findGroupKeys(reports))) {
    return [];
  }

  const {
    agro,
    telematics,
    favourite,
    wms,
    fields,
  } = reports;

  return [
    {
      key: reports.key,
      id: 'side-menu-reports-section',
      label: (
        <span className={SUBMENU_TITLE_CLASS_NAME}>
          {reports.label}
        </span>
      ),
      className: `
        ${SUBMENU_CLASS_NAME}
        ${highlightSection(currentOpenSection, reports.key)}
      `,
      icon: <ReportsIcon className="side-menu-icons" />,
      children: [
        ...buildItems(reports.items),
        ...buildReportsFavouriteGroup(favourite, favouriteItems, setFavouriteItems),
        ...buildReportsPersistentGroup({
          group: telematics,
          divider: true,
          favouriteItems,
          setFavouriteItems,
          expandedReportGroups,
          setExpandedReportGroups,
          selectedKey,
        }),
        ...buildReportsPersistentGroup({
          group: agro,
          divider: true,
          favouriteItems,
          setFavouriteItems,
          expandedReportGroups,
          setExpandedReportGroups,
          selectedKey,
        }),
        ...buildReportsPersistentGroup({
          group: wms,
          divider: true,
          favouriteItems,
          setFavouriteItems,
          expandedReportGroups,
          setExpandedReportGroups,
          selectedKey,
        }),
        ...buildReportsPersistentGroup({
          group: fields,
          favouriteItems,
          setFavouriteItems,
          expandedReportGroups,
          setExpandedReportGroups,
          selectedKey,
        }),
      ],
    },
  ];
};

const buildMapSection = (map, currentOpenSection) => {
  if (isEmpty(map) || isEmpty(findGroupKeys(map))) {
    return [];
  }

  return [
    {
      key: map.key,
      label: (
        <span className={SUBMENU_TITLE_CLASS_NAME}>
          {map.label}
        </span>
      ),
      className: `
        ${SUBMENU_CLASS_NAME}
        ${highlightSection(currentOpenSection, map.key)}
      `,
      icon: <ProductivityMap className="side-menu-icons" />,
      children: [
        ...buildGroup({ group: map.fields, divider: true }),
        ...buildGroup({ group: map.landParcel, divider: true }),
        ...buildItems(map.items),
      ],
    },
  ];
};

const buildFieldsSection = (fields, currentOpenSection) => {
  if (isEmpty(fields)) return [];

  return [
    {
      key: fields.key,
      label: (
        <span className={SUBMENU_TITLE_CLASS_NAME}>
          {fields.label}
        </span>
      ),
      className: `
        ${SUBMENU_CLASS_NAME}
        ${highlightSection(currentOpenSection, fields.key)}
      `,
      icon: <FieldIcon className="side-menu-icons" />,
      children: [
        ...buildItems(fields.items),
        { type: 'divider' },
        ...buildGroup({ group: fields.reports }),
      ],
    },
  ];
};

const buildWmsSection = (wms, currentOpenSection) => {
  if (isEmpty(wms) || isEmpty(findGroupKeys(wms))) {
    return [];
  }

  return [
    {
      key: wms.key,
      label: (
        <span className={SUBMENU_TITLE_CLASS_NAME}>
          {wms.label}
        </span>
      ),
      className: `
        ${SUBMENU_CLASS_NAME}
        ${highlightSection(currentOpenSection, wms.key)}
      `,
      icon: <DescriptionIcon className="side-menu-icons" />,
      children: [
        ...buildGroup({ group: wms.documents, divider: true }),
        ...buildGroup({ group: wms.directories, divider: true }),
        ...buildGroup({ group: wms.reports }),
      ],
    },
  ];
};

const buildMoreSection = (more, currentOpenSection) => {
  if (isEmpty(more)) return [];

  return [
    {
      key: more.key,
      label: (
        <span className={SUBMENU_TITLE_CLASS_NAME}>
          {more.label}
        </span>
      ),
      className: `
        ${SUBMENU_CLASS_NAME}
        ${highlightSection(currentOpenSection, more.key)}
      `,
      icon: <MoreHorizontalIcon className="side-menu-icons" />,
      children: buildItems(more.items),
    },
  ];
};

const buildPlatformSection = (platform) => {
  if (isEmpty(platform)) return [];

  return [
    {
      key: platform.key,
      label: (
        <Button
          type="link"
          href={platform.path}
          className={LINK_SINGLE_ITEM_CLASS_NAME}
          icon={<PlatformIcon className="side-menu-icons" />}
        >
          <span className={SUBMENU_TITLE_CLASS_NAME}>
            {platform.label}
          </span>
        </Button>
      ),
      style: { paddingLeft: '0px' },
      className: SINGLE_ITEM_CLASS_NAME,
    },
  ];
};

const useSideMenuItems = (
  sideMenuOptions,
  collapsed,
  isHovered,
  selectedKey,
  currentOpenSection,
) => {
  const {
    monitoringCenter, agro, telematics, reports,
    map, fields, wms, more, platform,
  } = sideMenuOptions;

  const [favouriteItems, setFavouriteItems] = useState(reports.favourite?.items || []);
  const [expandedReportGroups, setExpandedReportGroups] = useState({});

  useEffect(() => {
    if (isHovered ? false : collapsed) {
      setExpandedReportGroups({});
    }

    setExpandedReportGroups((prev) => {
      const updated = { ...prev };
      Object.keys(prev).forEach((key) => {
        updated[key] = !(isHovered ? false : collapsed);
      });
      return updated;
    });
  }, [collapsed, isHovered]);

  const [monitoringCenterSection, setMonitoringCenterSection] = useState([]);
  const [agroSection, setAgroSection] = useState([]);
  const [telematicsSection, setTelematicsSection] = useState([]);
  const [mapSection, setMapSection] = useState([]);
  const [fieldsSection, setFieldsSection] = useState([]);
  const [wmsSection, setWmsSection] = useState([]);
  const [moreSection, setMoreSection] = useState([]);
  const [platformSection, setPlatformSection] = useState([]);
  const [reportsSection, setReportsSection] = useState([]);

  useEffect(() => {
    setMonitoringCenterSection(buildMonitoringCenterSection(monitoringCenter, currentOpenSection));
    setAgroSection(buildAgroSection(agro, currentOpenSection));
    setTelematicsSection(buildTelematicsSection(telematics, currentOpenSection));
    setMapSection(buildMapSection(map, currentOpenSection));
    setFieldsSection(buildFieldsSection(fields, currentOpenSection));
    setWmsSection(buildWmsSection(wms, currentOpenSection));
    setMoreSection(buildMoreSection(more, currentOpenSection));
    setPlatformSection(buildPlatformSection(platform));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setReportsSection(buildReportsSection(
      reports,
      favouriteItems,
      setFavouriteItems,
      expandedReportGroups,
      setExpandedReportGroups,
      selectedKey,
      currentOpenSection,
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteItems.length, Object.entries(expandedReportGroups).length]);

  return [
    ...monitoringCenterSection,
    ...agroSection,
    ...telematicsSection,
    ...reportsSection,
    ...mapSection,
    ...fieldsSection,
    ...wmsSection,
    ...moreSection,
    ...platformSection,
  ];
};

export default useSideMenuItems;
