import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import FavouriteButton from 'components/favourite-button';
import { Button } from 'antd';
import { divider } from '../helpers';

const ITEMS_LIMIT = 7;

const buildItems = ({
  items,
  favouriteItems,
  setFavouriteItems,
}) => (
  items.map((item) => ({
    key: item.key,
    label: (
      <Button
        type="link"
        href={item.path}
        className="trigger-dropdown__link"
      >
        {item.label}
        <FavouriteButton
          item={item}
          itemsLimit={ITEMS_LIMIT}
          favouriteItems={favouriteItems}
          setFavouriteItems={setFavouriteItems}
          className="trigger-dropdown__favourite-button"
          saveKey="selected_favorite_fast_action_keys"
        />
      </Button>
    ),
    className: 'trigger-dropdown__item',
  }))
);

const buildFavouriteItemGroup = (
  availableSuggestions,
  favouriteItems,
  setFavouriteItems,
) => {
  if (!favouriteItems.length) return [];

  return [
    {
      key: 'favourite',
      label: I18n.t('shared.side_menu.favourites'),
      type: 'group',
      children: buildItems({
        items: favouriteItems,
        favouriteItems,
        setFavouriteItems,
      }),
    },
    (favouriteItems.length < ITEMS_LIMIT || availableSuggestions.length > 0) && divider,
  ];
};

const buildAdditionalItems = (
  availableAdditional,
  favouriteItems,
  setFavouriteItems,
) => ([
  divider,
  {
    key: 'additional',
    label: I18n.t('shared.top_menu.additional'),
    className: 'trigger-dropdown__additional-item',
    popupClassName: 'trigger-submenu-popup-additional',
    popupOffset: [2, 0],
    children: buildItems({
      items: availableAdditional,
      favouriteItems,
      setFavouriteItems,
    }),
  },
]);

const useTrigger = (triggerOptions) => {
  const { selectedFavouriteItems, allItems } = triggerOptions;

  const [favouriteItems, setFavouriteItems] = useState(selectedFavouriteItems);

  const [favouriteGroupItems, setFavouriteGroupItems] = useState([]);
  const [suggestionItems, setSuggestionItems] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);

  useEffect(() => {
    const favouriteKeys = favouriteItems.map(({ key }) => key);
    const availableItemsItems = allItems
      .filter((additional) => !favouriteKeys.includes(additional.key));

    const availableSuggestions = availableItemsItems
      .slice(0, ITEMS_LIMIT - favouriteKeys.length);

    const suggestionKeys = availableSuggestions.map(({ key }) => key);
    const availableAdditional = availableItemsItems
      .filter((add) => !suggestionKeys.includes(add.key));

    setFavouriteGroupItems(
      buildFavouriteItemGroup(availableSuggestions, favouriteItems, setFavouriteItems),
    );
    setSuggestionItems(
      buildItems({ items: availableSuggestions, favouriteItems, setFavouriteItems }),
    );
    setAdditionalItems(
      buildAdditionalItems(availableAdditional, favouriteItems, setFavouriteItems),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteItems]);

  return {
    items: [
      ...favouriteGroupItems,
      ...suggestionItems,
      ...additionalItems,
    ],
    showTrigger: allItems.length,
  };
};

export default useTrigger;
