/* global window */
import React, { useMemo } from 'react';
import {
  Avatar,
  Button,
  Space,
  Divider,
  Dropdown,
} from 'antd';
import {
  ArrowRightIcon,
  DefaultCompanyIcon,
  ActivityIcon,
  SettingsIcon,
  LanguageIcon,
  AcademyIcon,
  KnowledgeBaseIcon,
  EmailIcon,
  LogoutIcon,
  QuestionIcon,
  ZendeskIcon,
} from 'components/cwo-icons';
import { isEmpty } from 'lodash-es';
import TopMenuFieldGroups from 'components/top-menu-field-groups';
import ZendeskWidget from 'components/zendesk-widget';
import TopMenuInstantNotifications from 'components/top-menu-instant-notifications';
import {
  bool, func, number, shape, string,
} from 'prop-types';
import {
  buildSection,
  languageLabel,
  userLabel,
  link,
  divider,
} from '../helpers';
import { topMenuOptionsPropTypes } from '../propTypes';

const icons = {
  'account-activity': <ActivityIcon />,
  'account-settings': <SettingsIcon />,
  'account-language': <LanguageIcon />,
  'account-sign-out': <LogoutIcon />,
  'company-company': <DefaultCompanyIcon />,
  'question-email': <EmailIcon />,
  'question-knowledge-base': <KnowledgeBaseIcon />,
  'question-cropio-academy': <AcademyIcon />,
  'question-zendesk-widget': <ZendeskIcon />,
};

const RightPart = ({
  topMenuOptions,
  currentLocale,
  filterButtonsRef,
  questionButtonRef,
  dispatcherBlockRef,
  filterDrawerOpen,
  setFilterDrawerOpen,
  filterCollapseActiveKey,
  setFilterCollapseActiveKey,
}) => {
  const {
    filter,
    question,
    notifications,
    settings,
    account,
  } = topMenuOptions;

  const buildIcon = (item) => {
    const { key, logoSrc, label } = item;
    const imgTag = logoSrc?.includes('/') && <img src={logoSrc} alt={label} />;

    if (key === 'company-company') {
      return (
        <Avatar
          size="large"
          shape="square"
          className="navigation-account-management__avatar"
          icon={imgTag || icons[key]}
        />
      );
    }

    if (['account-user', 'account_section'].includes(key)) {
      return (
        <Avatar
          size="large"
          shape="circle"
          className="navigation-account-management__avatar"
          icon={imgTag || logoSrc}
        />
      );
    }

    return icons[key];
  };

  const signOutButton = (item) => (
    <Button
      type="link"
      className="navigation-dropdown-menu-account__sign-out-button"
      onClick={async () => {
        try {
          await fetch(item.path, { method: 'DELETE' });
          window.location.href = window.location.origin;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }}
    >
      {item.label}
    </Button>
  );

  const buildLabel = (item) => {
    switch (item.key) {
      case 'account-sign-out':
        return signOutButton(item);
      case 'question-zendesk-widget':
        return <li><ZendeskWidget /></li>;
      case 'account-language':
        return languageLabel(item, currentLocale);
      case 'account-user':
        return userLabel(item);
      default:
        return link(item.path, item.label);
    }
  };

  const changeLanguage = (locale) => {
    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.set('locale', locale);
    window.location.href = currentUrl.toString();
  };

  const buildLanguageItems = (items) => {
    if (!items) return {};

    return {
      popupOffset: [2, -160],
      popupClassName: 'navigation-dropdown-menu-account__language-popup',
      className: 'navigation-dropdown-menu-account__language-item',
      children: items.map((item) => ({
        key: item.key,
        disabled: currentLocale === item.key,
        label: (
          <a
            onClick={() => changeLanguage(item.key)}
            className="navigation-side-menu__link"
            href={item.path}
          >
            {item.label}
          </a>
        ),
      })),
    };
  };

  const buildDropdownItemGroup = (section, sectionGroupKey) => {
    const { [sectionGroupKey]: sectionGroup } = section;
    if (!section) return {};

    return (
      {
        key: sectionGroup.key,
        type: 'group',
        label: sectionGroup.label,
        children: sectionGroup.items.map((item) => {
          const nonClickableItem = item.key === 'company-company' && 'non-clickable-item';

          if (item.type === 'divider') return divider;

          return {
            key: item.key,
            icon: buildIcon(item),
            label: buildLabel(item),
            className: `${nonClickableItem}`,
            ...buildLanguageItems(item.children),
          };
        }),
      }
    );
  };

  const questionMenu = useMemo(() => ({
    items: buildSection(question, buildDropdownItemGroup),
    className: 'navigation-dropdown-menu-question',
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const settingsMenu = useMemo(() => ({
    items: buildSection(settings, buildDropdownItemGroup),
    className: 'navigation-dropdown-menu-settings',

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const accountMenu = useMemo(() => ({
    items: buildSection(account, buildDropdownItemGroup),
    defaultSelectedKeys: [currentLocale],
    triggerSubMenuAction: 'click',
    expandIcon: <ArrowRightIcon />,
    className: 'navigation-dropdown-menu-account',
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return (
    <Space className="navigation-top-menu-right-part">
      <TopMenuFieldGroups
        options={filter}
        filterButtonsRef={filterButtonsRef}
        dispatcherBlockRef={dispatcherBlockRef}
        filterDrawerOpen={filterDrawerOpen}
        setFilterDrawerOpen={setFilterDrawerOpen}
        filterCollapseActiveKey={filterCollapseActiveKey}
        setFilterCollapseActiveKey={setFilterCollapseActiveKey}
      />

      <Divider type="vertical" />

      <Space className="navigation-account-management">
        <TopMenuInstantNotifications
          alertsNotifications={notifications.alertsNotifications}
          instantNotifications={notifications.instantNotifications}
        />

        <Dropdown
          overlayClassName="navigation-dropdown-container__question"
          menu={questionMenu}
          trigger={['click']}
        >
          <Button
            ref={questionButtonRef}
            type="text"
            icon={<QuestionIcon />}
            className="navigation-account-management__question-button"
          />
        </Dropdown>

        {!isEmpty(settings) && (
          <Dropdown
            overlayClassName="navigation-dropdown-container__settings"
            menu={settingsMenu}
            trigger={['click']}
          >
            <Button
              type="text"
              icon={<SettingsIcon />}
              className="navigation-account-management__settings-button"
            />
          </Dropdown>
        )}

        <Dropdown
          overlayClassName="navigation-dropdown-container__account"
          trigger={['click']}
          menu={accountMenu}
        >
          <Button
            type="link"
            icon={buildIcon(account)}
            className="navigation-account-management__avatar-button"
          />
        </Dropdown>
      </Space>
    </Space>
  );
};

RightPart.propTypes = {
  topMenuOptions: shape(topMenuOptionsPropTypes),
  currentLocale: string.isRequired,
  filterButtonsRef: shape({}),
  questionButtonRef: shape({}),
  dispatcherBlockRef: shape({}),
  filterDrawerOpen: bool,
  setFilterDrawerOpen: func,
  filterCollapseActiveKey: number,
  setFilterCollapseActiveKey: func,
};

RightPart.defaultProps = {
  topMenuOptions: {
    account: {},
    filter: {},
    alertsNotification: {},
    question: {},
    settings: {},
  },
  filterButtonsRef: null,
  questionButtonRef: null,
  dispatcherBlockRef: null,
  filterDrawerOpen: false,
  setFilterDrawerOpen: () => {},
  filterCollapseActiveKey: 1,
  setFilterCollapseActiveKey: () => {},
};

export default RightPart;
