/* global window, document */
import React from 'react';
import I18n from 'i18n-js';
import {
  userLocale,
  userName,
  userEmail,
  companyName,
  userManager,
} from 'helpers/userSessionSettings';

const ZendeskWidget = () => {
  const scriptExists = () => !!document.querySelector('#ze-snippet');

  const activateWidget = () => {
    window.zE.activate(); // legacy because 'open' does not work properly when widget is hidden;
  };

  const loadStaticScript = () => {
    const snippetScript = document.createElement('script');
    snippetScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=e3975b8f-6479-4be9-8f04-395360c62a56';
    snippetScript.type = 'text/javascript';
    snippetScript.id = 'ze-snippet';
    document.body.prepend(snippetScript);
  };

  const loadCustomSettings = () => {
    const staticScript = document.querySelector('#ze-snippet');
    staticScript.addEventListener('load', () => {
      // Must be on top of the script due to https://developer.zendesk.com/embeddables/docs/widget/core#setlocale
      window.zE('webWidget', 'setLocale', userLocale); // set widget locale based on Cropwise selected locale
      window.zE('webWidget', 'identify', {
        name: userName,
        email: userEmail,
        organization: companyName,
      });
      window.zE('webWidget', 'prefill', { // prefill ticket form with user name and email
        name: {
          value: userName,
          readOnly: true, // optional
        },
        email: {
          value: userEmail,
          readOnly: true, // optional
        },
      });
      window.zE('webWidget', 'open'); // opens widget when it appears on page
      window.zE('webWidget:on', 'close', () => { // hides widget button when user minimizes widget
        window.zE('webWidget', 'hide');
      });
    });
    window.zESettings = {
      webWidget: {
        color: {
          theme: '#232730',
        },
        chat: {
          prechatForm: {
            greeting: {
              '*': 'Please fill out the form below to chat with us',
              ru: 'Пожалуйста, заполните форму, чтобы начать чат с нами',
              uk: 'Будь ласка, заповніть форму, щоб розпочати чат з нами',
            },
          },
          title: {
            '*': 'Cropwise Operations',
          },
          concierge: {
            name: 'Chat Bot',
            title: {
              '*': 'Live support',
              ru: 'Онлайн помощь',
              uk: 'Онлайн допомога',
            },
          },
          departments: {
            enabled: [],
            select: userManager,
          },
        },
        helpCenter: {
          // Disable until FAQ appears on Syngenta help
          suppress: true,
        },
        contactForm: {
          ticketForms: [ // prefills Product field
            {
              id: '1500000624382', // EAME - Cropwise Operations Default Form id
              fields: [
                {
                  id: 360014547793,
                  prefill: {
                    '*': 'operations_eameproduct', // tag of 'Operations' product
                  },
                },
              ],
            },
          ],
          title: {
            '*': 'Leave us a message',
            ru: 'Оставьте сообщение',
            uk: 'Залиште повідомлення',
          },
          selectTicketForm: {
            '*': 'Please choose:',
            ru: 'Пожалуйста, выберите:',
            uk: 'Будь ласка, виберіть:',
          },
          // This is default ticket form for Cropwise Operations
          // it is not assigned (visible) to other brands, only to Cropwise Operations
          subject: true,
          attachments: true,
        },
        launcher: {
          chatLabel: {
            '*': 'Online chat',
            ru: 'Онлайн чат',
            uk: 'Онлайн чат',
          },
        },
      },
    };
  };

  const loadChatWidget = () => {
    loadStaticScript();
    loadCustomSettings();
  };

  const handleWindowOpen = () => {
    if (scriptExists()) {
      activateWidget();
    } else {
      loadChatWidget();
    }
  };

  return (
    <div
      role="link"
      tabIndex={0}
      onKeyPress={handleWindowOpen}
      onClick={handleWindowOpen}
      className="zendesk-chat-widget-link"
    >
      {I18n.t('shared.top_menu.online_support')}
    </div>
  );
};

export default ZendeskWidget;
