import React from 'react';
import { monitoring_center_path } from 'js-routes/generated/routes';
import {
  bool, func, shape, string,
} from 'prop-types';
import { Space, Button } from 'antd';
import {
  CwoLogo,
  CwoLogoRu,
  OpenMenuIcon,
  CloseMenuIcon,
} from 'components/cwo-icons';

const logoForCountry = {
  default: <CwoLogo />,
  ru: <CwoLogoRu />,
};

const LeftPart = ({
  collapsed,
  setCollapsed,
  sideMenuButtonRef,
  currentCountry,
}) => (
  <Space className="navigation-top-menu-left-part">
    <Button
      ref={sideMenuButtonRef}
      type="link"
      className="navigation-top-menu-left-part__sider-button"
      onClick={() => setCollapsed(!collapsed)}
      icon={collapsed ? <OpenMenuIcon /> : <CloseMenuIcon />}
    />
    <a href={monitoring_center_path()}>
      {logoForCountry[currentCountry] || logoForCountry.default}
    </a>
  </Space>
);

LeftPart.propTypes = {
  collapsed: bool,
  setCollapsed: func.isRequired,
  sideMenuButtonRef: shape({}),
  currentCountry: string.isRequired,
};

LeftPart.defaultProps = {
  collapsed: true,
  sideMenuButtonRef: null,
};

export default LeftPart;
